import styled from '@emotion/styled';
import { cover } from '~/shared/utils/styled';
import { breakpoints, getClampFullRange } from '~/theme';
import { Text } from '~/shared/components';

type StyledGridProps = {
    columnCount: number;
};

export const StyledGrid = styled.ul<StyledGridProps>(({ theme, columnCount }) => ({
    display: 'grid',
    columnGap: getClampFullRange(theme.spacesRaw[3], theme.spacesRaw[5]),
    rowGap: getClampFullRange(theme.spacesRaw[3], theme.spacesRaw[6]),

    [breakpoints.sm]: {
        gridTemplateColumns: '1fr 1fr',
    },
    [breakpoints.md]: {
        gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
    },
}));

export const StyledItem = styled.li(({ theme }) => ({
    display: 'flex',
    gap: theme.spaces['3'],
    area: 'usp',
    [breakpoints.md]: {
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spaces['1'],
    },
}));

export const StyledImage = styled.div(({ theme }) => ({
    marginTop: theme.spaces[1], // Adds a better visual alignment
    width: getClampFullRange(theme.spacesRaw[5], theme.spacesRaw[6]),
    flexShrink: 0,
    [breakpoints.md]: {
        marginTop: 0,
    },
}));

export const StyledContent = styled(Text)({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',

    [breakpoints.md]: {
        textAlign: 'center',
    },
});

export const StyledLink = styled.a({
    ...cover,
});
