import React from 'react';
import { M61UspModule } from '~/lib/data-contract';
import { RouterLink, LegacyImage, Text } from '~/shared/components';
import { VisuallyHidden } from '~/shared/components/VisuallyHidden';
import { useThemeShade } from '~/theme';
import { ModuleContainer } from '../ModuleContainer';
import { StyledContent, StyledGrid, StyledImage, StyledItem, StyledLink } from './styled';

export type M61USPProps = M61UspModule;

export const M61USP = ({ items, ...rest }: M61USPProps) => {
    const { textShade, headlineShade } = useThemeShade({});
    const uspCount = items?.length ?? 0;
    const maxPerLine = uspCount % 3 ? 4 : 3;
    const columnCount = Math.min(uspCount, maxPerLine);

    return (
        <ModuleContainer hasGutter {...rest}>
            <StyledGrid columnCount={columnCount}>
                {items?.map(({ headline, text, image, link }, index) => (
                    <StyledItem key={index}>
                        {image?.src && (
                            <StyledImage>
                                <LegacyImage
                                    src={image.src}
                                    alt={image.alt}
                                    sizes="60px"
                                    mimeType="image/svg+xml"
                                    skeletonShade="none"
                                    showSpinner={false}
                                    invert={textShade === 'paragraphInverted'}
                                />
                            </StyledImage>
                        )}
                        <StyledContent>
                            {headline && <Text as="b" children={headline} shade={headlineShade} />}
                            {text && (
                                <Text
                                    as="span"
                                    variant="bodySm"
                                    children={text}
                                    shade={textShade}
                                />
                            )}
                            {link?.url && (
                                <RouterLink href={link.url}>
                                    <StyledLink title={link.title} target={link.target}>
                                        <VisuallyHidden>{link.text}</VisuallyHidden>
                                    </StyledLink>
                                </RouterLink>
                            )}
                        </StyledContent>
                    </StyledItem>
                ))}
            </StyledGrid>
        </ModuleContainer>
    );
};
